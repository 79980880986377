<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat style="user-select: none">
      <div class="mx-auto" style="font-size: 20px">Mnemonist Software</div>
    </v-app-bar>

    <!-- Header -->
    <Header :title="$t('events.numbers')" :memo="this.phase === 'recall' ? time : -1" ref="header"/>

    <!-- Pagination -->
    <Pagination :page="page" :pages="pages" :on-page-change="onPageChange"/>

    <!-- Game: memo -->
    <div id="memo" v-if="phase === 'memo'">
      <div v-for="row in memoRows[page - 1]" :key="page + '-' + row.number"
           class="memo-row d-flex flex-row flex-nowrap justify-start align-center">
        <div class="memo-row-number">{{ row.number }}.</div>
        <div v-for="number in row.numbers" :key="number.id" class="memo-row-value"
             :style="{'background-color': highlight.includes(number.id) ? '#89bbee' : ''}"
             :class="{'highlight': highlight.includes(number.id)}">
          {{ number.value }}
        </div>
      </div>
    </div>

    <!-- Game: recall -->
    <div id="recall" v-if="phase === 'recall'">
      <div v-for="row in recallRows[page - 1]" :key="page + '-' + row.number"
           class="recall-row d-flex flex-row flex-nowrap justify-start align-center">
        <div class="recall-row-number">{{ row.number }}.</div>
        <div v-for="number in row.numbers" :key="number.id" class="recall-row-value"
             :style="{'background-color': highlight.includes(number.id) ? '#89bbee' : ''}">
          <input type="number" inputmode="numeric" class="recall-row-value-text-field" :id="'recall-input-' + number.id"
                 v-model="recall[number.id]" @input="changedRecallValue(number.id)"
                 @click="clickedRecallValue(number.id)"/>
        </div>
      </div>
    </div>

    <!-- Game: result -->
    <div id="result" v-if="phase === 'result'">
      <div v-for="row in recallRows[page - 1]" :key="page + '-' + row.number"
           class="result-row d-flex flex-row flex-nowrap justify-start align-center"
           @mouseover="resultHighlight = row.number" @mouseleave="resultHighlight = false">
        <div class="result-row-number">{{ row.number }}.</div>
        <div v-for="number in row.numbers" :key="number.id" class="result-row-value"
             :class="[resultRows[number.id].class]">
          <input type="number" inputmode="numeric" class="result-row-value-text-field" :id="'result-input-' + number.id"
                 :value="row.number === resultHighlight ? memo[number.id] : recall[number.id]" readonly/>
        </div>
      </div>
    </div>

    <!-- Countdown -->
    <Countdown :title="$t('events.numbers')" ref="countdown"/>

    <!--Mobile arrows-->
    <MobileArrows v-if="['memo', 'recall', 'result'].includes(phase) && $vuetify.breakpoint.width <= 600"
                  :on-left="onLeft" :on-right="onRight"/>
    <div v-if="['memo', 'recall', 'result'].includes(phase) && $vuetify.breakpoint.width <= 600"
         style="height: 60px; width: 100%"></div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderTraining';
import Pagination from '../components/Pagination';
import Countdown from '../components/Countdown';
import MobileArrows from '../components/MobileArrows';

export default {
  name: 'TrainingNumbers',
  components: {Header, Pagination, Countdown, MobileArrows},
  data: () => ({
    page: 1, pages: 1, time: 0, score: -1, official: false, live: 0, phase: 'wait', highlight: [], input: 0,
    memo: [], recall: [], begin: 0,
    memoRows: [], recallRows: [], resultRows: [], resultHighlight: false, doubleClickMemoEnd: false
  }),
  computed: {
    params() {
      return this.$api.game.preferences;
    },
    liveTime() {
      if (this.phase === 'memo') return 0;
      else if (this.phase === 'result') return Math.min(this.time, this.params.memo * 1000);
      else return Math.max(-this.time, -this.params.memo * 1000);
    },
    onLeft() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page - 1 > 0) this.onPageChange(this.page - 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 180 >= 180 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else if (this.phase === 'recall') {
        return () => {
          if (this.input === 0) return;
          this.input -= 1;
          if ((this.input + 1) % 180 === 0) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              setTimeout(() => f.scrollIntoView({behavior: 'smooth', block: 'center'}), 12);
              setTimeout(() => {
                f.type = 'text';
                f.selectionStart = f.selectionEnd = 10000;
                f.type = 'number';
              }, 0);
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            setTimeout(() => f.scrollIntoView({behavior: 'smooth', block: 'center'}), 12);
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }
        };
      } else return () => ({});
    },
    onRight() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          if (this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
            this.$refs.header.reset();
          else this.doubleClickMemoEnd = this.highlight[0] + this.params.highlight >= this.params.number;
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 180 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else if (this.phase === 'recall') {
        return () => {
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 180 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              setTimeout(() => f.scrollIntoView({behavior: 'smooth', block: 'center'}), 12);
              setTimeout(() => {
                f.type = 'text';
                f.selectionStart = f.selectionEnd = 10000;
                f.type = 'number';
              }, 0);
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            setTimeout(() => f.scrollIntoView({behavior: 'smooth', block: 'center'}), 12);
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }
        };
      } else return () => ({});
    }
  },
  watch: {
    highlight: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    recall: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    time: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    }
  },
  mounted() {
    this.pages = Math.ceil(this.params.number / 180);
    this.time = this.$api.isGameReadOnly() ? this.$api.game.time : this.$api.isGameLiveNow() ? this.$api.game.time : 0;
    this.score = this.$api.isGameReadOnly() ? this.$api.game.score : this.$api.isGameLiveNow() ? this.$api.game.score : -1;
    this.official = this.$api.game.official;
    this.live = parseInt(this.$api.game.live);
    this.phase = this.$api.isGameReadOnly() ? 'result' : 'wait';
    for (let i = 0; i < this.params.highlight; i++) this.highlight.push(i);
    this.memo = this.$api.game.data;

    if (this.$api.isGameReadOnly() || this.$api.isGameLiveNow())
      this.recall = this.$api.game.recall;
    else for (let i = 0; i < this.params.number; i++) this.recall.push('');

    for (let i = 0; i < this.memo.length; i += 1) {
      if (i % 180 === 0) this.memoRows.push([]);
      if (i % 12 === 0) this.memoRows[this.memoRows.length - 1].push({number: Math.floor(i / 12) + 1, numbers: []});
      this.memoRows[this.memoRows.length - 1][this.memoRows[this.memoRows.length - 1].length - 1].numbers.push({
        id: i, value: this.memo[i]
      });
    }

    for (let i = 0; i < this.memo.length; i += 1) {
      if (i % 180 === 0) this.recallRows.push([]);
      if (i % 12 === 0) this.recallRows[this.recallRows.length - 1].push({number: Math.floor(i / 12) + 1, numbers: []});
      this.recallRows[this.recallRows.length - 1][this.recallRows[this.recallRows.length - 1].length - 1].numbers.push({id: i});
    }

    if (!this.$api.isGameReadOnly()) {
      if (this.$api.isGameLiveNow()) {
        let now = new Date().getTime();
        if (now < this.live) {
          this.$refs.countdown.start(new Date().getTime() + this.live - now, false, this.startMemo);
        } else if (now < this.live + this.params.memo * 1000 && this.time === 0) {
          this.startMemo(this.live + this.params.memo * 1000 - now);
          this.begin = this.live;
        } else if (now < this.live + this.params.memo * 1000 + 10000) {
          this.phase = 'wait';
          this.$refs.countdown.start(this.live + this.params.memo * 1000 + 10000, false, this.startRecall);
          this.time = Math.abs(this.time);
        } else if (now < this.live + 1000 * (this.params.memo + 10 + this.params.recall) && this.time < 0) {
          this.startRecall(this.live + this.params.memo * 1000 + 10000 + this.params.recall * 1000 - now);
          this.time = Math.abs(this.time);
        } else this.stopRecall();
      } else this.$refs.countdown.start(new Date().getTime() + 10000, true, this.startMemo);
    } else this.stopRecall();

    document.addEventListener('keydown', this.eventListener);
  },
  beforeDestroy() {
    clearTimeout(this.$refs.countdown.countdown);
    clearTimeout(this.$refs.header.countdown);
    this.$api.game.gid = 'local';
    this.$api.submitGame();
    document.removeEventListener('keydown', this.eventListener);
  },
  methods: {
    changedRecallValue(id) {
      this.recall[id] = this.recall[id].charAt(this.recall[id].length - 1);
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(this.recall[id])) {
        this.recall[id] = '';
      } else {
        if (id >= this.params.number - 1) return;
        this.input += 1;
        if (this.input % 180 === 0) {
          this.onPageChange(parseInt(this.input / 180) + 1, false);
          setTimeout(() => {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-180');
            f.focus();
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }, 20);
        } else {
          this.setHighlight(this.input);
          let f = document.getElementById('recall-input-' + (id + 1));
          f.focus();
          setTimeout(() => {
            f.type = 'text';
            f.selectionStart = f.selectionEnd = 10000;
            f.type = 'number';
          }, 0);
        }
      }
    },
    clickedRecallValue(id) {
      this.input = id;
      this.setHighlight(this.input);
      let f = document.getElementById('recall-input-' + id);
      setTimeout(() => {
        f.type = 'text';
        f.selectionStart = f.selectionEnd = 10000;
        f.type = 'number';
      }, 0);
    },
    eventListener(e) {
      if (this.phase === 'memo') {
        if (e.key === 'ArrowRight' && this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
          this.$refs.header.reset();
        else this.doubleClickMemoEnd = e.key === 'ArrowRight' && this.highlight[0] + this.params.highlight >= this.params.number;

        if (e.key === 'ArrowLeft') {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 180 >= 180 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
        } else if (e.key === 'ArrowRight') {
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 180 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
        } else if (e.key === 'ArrowUp') {
          let next = this.highlight[0] - 12;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 180 >= 168) this.onPageChange(this.page - 1, false);
          }
        } else if (e.key === 'ArrowDown') {
          let next = this.highlight[0] + 12;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 180 < 12) this.onPageChange(this.page + 1, false);
          }
        } else if (e.key === 'Enter') {
          this.$refs.header.reset();
        }
      }

      if (this.phase === 'recall') {
        if (e.key === 'ArrowLeft') {
          if (this.input === 0) return;
          this.input -= 1;
          if ((this.input + 1) % 180 === 0) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              setTimeout(() => {
                f.type = 'text';
                f.selectionStart = f.selectionEnd = 10000;
                f.type = 'number';
              }, 0);
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }
        } else if (e.key === 'ArrowRight') {
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 180 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              setTimeout(() => {
                f.type = 'text';
                f.selectionStart = f.selectionEnd = 10000;
                f.type = 'number';
              }, 0);
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          if (this.input - 12 < 0) return;
          this.input -= 12;
          if (this.input % 180 >= 168) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              setTimeout(() => {
                f.type = 'text';
                f.selectionStart = f.selectionEnd = 10000;
                f.type = 'number';
              }, 0);
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input))
            f.focus();
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          if (this.input + 12 >= this.params.number) return;
          this.input += 12;
          if (this.input % 180 < 12) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              setTimeout(() => {
                f.type = 'text';
                f.selectionStart = f.selectionEnd = 10000;
                f.type = 'number';
              }, 0);
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }
        } else if (e.key === 'Backspace' && this.input > 0) {
          this.input -= 1;
          if ((this.input + 1) % 180 === 0) {
            let inp = this.input;
            this.onPageChange(1, false);
            setTimeout(() => {
              this.recall[inp] = '';
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              setTimeout(() => {
                f.type = 'text';
                f.selectionStart = f.selectionEnd = 10000;
                f.type = 'number';
              }, 0);
              this.input = inp;
            }, 20);
          } else {
            this.recall[this.input] = '';
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            setTimeout(() => {
              f.type = 'text';
              f.selectionStart = f.selectionEnd = 10000;
              f.type = 'number';
            }, 0);
          }
        } else if (e.key === 'Enter') {
          this.$refs.header.reset();
        }
      }

      if (e.key === ',') {
        e.preventDefault();
        if (this.page - 1 >= 1) this.onPageChange(this.page - 1);
      } else if (e.key === '.') {
        e.preventDefault();
        if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
      } else if (e.key === ' ' && ['memo', 'recall'].includes(this.phase)) {
        e.preventDefault();
        this.onPageChange(1);
      }
    },
    setHighlight(id) {
      if (this.highlight.includes(id)) return;
      let newHighlight = [];
      for (let i = 0; i < this.params.highlight; i++)
        newHighlight.push(this.params.highlight * Math.floor(id / this.params.highlight) + i);
      this.highlight = newHighlight;
    },
    startMemo(total = this.params.memo * 1000) {
      this.phase = 'memo';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopMemo);
      this.begin = new Date().getTime();
    },
    startRecall(total = this.params.recall * 1000) {
      this.onPageChange(1);
      this.input = 0;
      this.phase = 'recall';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopRecall);
    },
    stopMemo() {
      this.phase = 'wait';
      let delay = this.live === 0 ? 10000 : this.live + 10000 + this.params.memo * 1000 - new Date().getTime();
      this.$refs.countdown.start(new Date().getTime() + delay, this.live === 0, this.startRecall);
      this.time = Math.min(Math.round((new Date().getTime() - this.begin) / 10), this.params.memo * 100);
    },
    stopRecall() {
      this.phase = 'result';
      this.score = 0;
      for (let i = 0; i < this.memo.length; i += 12) {
        let correct = 0, incorrect = 0;
        for (let j = 0; j < 12 && j + i < this.memo.length; j++) {
          if (this.recall[i + j] === this.memo[i + j].toString()) correct++;
          else if (this.recall[i + j] !== '') incorrect++;
          if (this.recall[i + j] === this.memo[i + j].toString())
            this.resultRows.push({class: 'result-correct'});
          else if (this.recall[i + j] === '')
            this.resultRows.push({class: 'result-empty'});
          else this.resultRows.push({class: 'result-incorrect'});
        }
        if (incorrect === 0) this.score += correct;
        else if (incorrect <= 6 && correct >= 4) this.score += correct;
      }

      this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);

      this.$refs.header.setScore(this.score, Math.min(this.time, 100 * this.params.memo));
      if (!this.$api.isGameReadOnly()) this.$api.submitGame(this.time, this.recall).then(() => {
        this.$store.commit('setMyResults', []);
        this.$api.init().then(() => {
          this.$store.commit('setProfile', this.$api.profile);
          let bestScore = this.$store.state.profile.events['numbers'].score;
          let bestTime = this.$store.state.profile.events['numbers'].time;
          if (this.official && this.score === bestScore && this.time === bestTime) this.$store.commit('showAdvancedDialog', {
            title: this.$t('dialogs.personal-best-title'), sections: [{
              title: this.$t('dialogs.personal-best-section-title'),
              icon: 'mdi-trophy-award',
              content: this.$t('dialogs.personal-best-section-content')
            }]
          });
        });
      });
    },
    onPageChange(page, hard = true) {
      this.page = page;
      window.scrollTo({top: 0, behavior: 'auto'});
      setTimeout(() => {
        if (this.phase === 'memo') {
          if (hard) this.setHighlight((page - 1) * 180);
        } else if (this.phase === 'recall') {
          this.input = (page - 1) * 180;
          this.setHighlight(this.input);
          let f = document.getElementById('recall-input-' + (this.input));
          f.focus();
          setTimeout(() => {
            f.type = 'text';
            f.selectionStart = f.selectionEnd = 10000;
            f.type = 'number';
          }, 0);
        }
      }, 10);
    }
  }
}
</script>

<style scoped>
#memo {
  margin: -36px auto 0 auto;
  width: 252px;
}

.memo-row {
  height: 40px;
}

.memo-row-number {
  color: #336799;
  font-size: 22px;
  font-weight: 700;
  margin-right: 10px;
  text-align: right;
  width: 50px;
}

.memo-row-value {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  width: 16px;
}

#recall {
  margin: 0 auto;
  width: 348px;
}

.recall-row {
  height: 40px;
}

.recall-row-number {
  color: #336799;
  font-size: 22px;
  font-weight: 700;
  margin-right: 10px;
  text-align: right;
  width: 50px;
}

.recall-row-value {
  height: 35px;
  padding: 0 2px;
  width: 24px;
}

.recall-row-value-text-field {
  border-bottom: 1px solid black;
  font-size: 19px;
  height: 34px;
  text-align: center;
  width: 20px;
}

#result {
  margin: 0 auto;
  width: 348px;
}

.result-row {
  height: 40px;
}

.result-row-number {
  color: #336799;
  font-size: 22px;
  font-weight: 700;
  margin-right: 10px;
  text-align: right;
  width: 50px;
}

.result-row-value {
  height: 35px;
  padding: 0 2px;
  width: 24px;
}

.result-row-value-text-field {
  border-bottom: 1px solid black;
  font-size: 19px;
  height: 34px;
  text-align: center;
  width: 20px;
}

input:focus {
  outline: none;
}

.result-correct {
  background-color: #82e599;
}

.result-incorrect {
  background-color: #eb7f7e;
}

.result-empty {
  background-color: #e0d8d5;
}

.result-row:hover .result-incorrect, .result-row:hover .result-empty {
  background: repeating-linear-gradient(72deg, #e0d8d5, #82e599 17%);
}

@media screen and (max-width: 600px) {
  #memo, #recall, #result {
    margin: 0 auto;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
